<template>
  <div>
    <v-snackbar v-model="snackErreur" color="red">
      {{snackMessage}}
      <v-btn text color="primary" @click.native="snackErreur = false">Close</v-btn>
    </v-snackbar>


    <h1>Suivi des trains du {{jourDebut.toLocaleDateString()}} au {{jourFin.toLocaleDateString()}}</h1>

    <div>
      <div style="display: flex; flex-direction: row; align-items: center">
        <v-btn color="success" class="mr-2 mb-4" @click="aujourdhui()" >Aujourd'hui</v-btn>
        <v-btn color="success" class="mr-2 mb-4" @click="jMoinsUn()" >J - 1</v-btn>
        <v-btn color="success" class="mr-2 mb-4" @click="jPlusUn()" >J + 1</v-btn>

        <div style="flex:1"></div>

        <v-menu v-model="datepicker1" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="datepicker1sJour" label="Date début" persistent-hint prepend-icon="mdi-calendar" v-bind="attrs" v-on="on" style="max-width: 250px" class="mx-3"></v-text-field>
          </template>
          <v-date-picker v-model="datepicker1Jour" no-title @input="choixJourDebut" ></v-date-picker>
        </v-menu>

        <v-menu v-model="datepicker2" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="datepicker2sJour" label="Date fin" persistent-hint prepend-icon="mdi-calendar" v-bind="attrs" v-on="on" style="max-width: 250px" class="mx-3"></v-text-field>
          </template>
          <v-date-picker v-model="datepicker2Jour" no-title @input="choixJourFin" ></v-date-picker>
        </v-menu>


        <v-btn color="success" class="mr-2 mb-4" @click="periode()" >Période</v-btn>
      </div>
      
      <v-data-table dense
        :headers="headers"
        :items="trains"
        :items-per-page="500"
        :search="search"
        class="elevation-1"
        @click="clickTableau()"
        :loading="loading"
      >
        <template v-slot:top>
          <v-text-field
            v-model="search"
            label="Recherche"
            class="mx-4"
          ></v-text-field>
        </template>

        <template v-slot:item.trDureeRetard="{ item }">
          <v-chip class="my-1"
            :color="getColor(item.trDureeRetard)"
            dark
          >
            {{ item.trDureeRetard }} mn
          </v-chip>
        </template>

        <template v-slot:item.trDateHeureDepart="{ item }">
          <div>
            {{ computeDate(item.trDateHeureDepart) }}
            </div>
        </template>

        <template v-slot:item.trajet="{ item }">
          <div>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  {{item.trajet}}
                </div>
              </template>
              <span>{{item.trajetTooltip}}</span>
            </v-tooltip>
          </div>
        </template>

        <template v-slot:item.trDateHeureEstimeeArrivee="{ item }">
          <div>
            {{ computeDate(item.trDateHeureEstimeeArrivee) }}
            </div>
        </template>

        <template v-slot:item.trDateHeureReelleArrivee="{ item }">
          <div>
            {{ computeDate(item.trDateHeureReelleArrivee) }}
            </div>
        </template>


      </v-data-table> 
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {

  data()  {
    return {
      search: '',
      trains: [],
      trainsFiltres: [],
      jourDebut: new Date(),
      jourFin: new Date(),
      headers: [
        { text: 'Prestataire', value: 'trPrestataire', align: 'start', sortable: true },
        { text: 'Train', value: 'trNumero', align: 'start', sortable: true },
        { text: 'Trajet', value: 'trajet', align: 'start', sortable: true },
        { text: 'Départ théorique', value: 'trDateHeureDepart', align: 'start', sortable: true },
        { text: 'Arrivée théorique', value: 'trDateHeureEstimeeArrivee', align: 'start', sortable: true },
        { text: 'Arrivée Réelle', value: 'trDateHeureReelleArrivee', align: 'start', sortable: true },
        { text: 'Retard', value: 'trDureeRetard', align: 'start', sortable: true },
        { text: 'Cause', value:"trCause", align: 'start', sortable: true },
        { text: 'Commentaire', value:"trCommentaire", align: 'start', sortable: true },
      ],
      loading: false,
      snackErreur: false,
      snackMessage: "",
      datepicker1: false,
      datepicker1Jour: undefined,
      datepicker1sJour: undefined,
      datepicker2: false,
      datepicker2Jour: undefined,
      datepicker2sJour: undefined,
      lieus: []
    }
  },

  created() {
    //this.aujourdhui();
    this.lireLieus();
  },

  methods: {
    lireLieus() {
      var url = "/api/liLieus?access_token=" + this.$user.id;
      axios.get(url)
        .then(function(response) {
          this.lieus = response.data
          this.aujourdhui();
        }.bind(this))
    },

    refresh() {
      this.loading = true;
      // Chargement des trains
      this.datepicker1Jour = this.dateToYYYYMMDD(this.jourDebut)
      this.datepicker1sJour = this.dateToDDMMYYYY(this.jourDebut)

      this.datepicker2Jour = this.dateToYYYYMMDD(this.jourFin)
      this.datepicker2sJour = this.dateToDDMMYYYY(this.jourFin)

      const jourDebut = this.dateToYYYYMMDD(this.jourDebut)
      const jourFin = this.dateToYYYYMMDD(this.jourFin)
      var filter = {
        "where": {
          "or": [
            {
              "and": [
                {"trDateHeureDepart": {"gte": jourDebut}},
                {"trDateHeureDepart": {"lte": jourFin}}
              ]
            },
            {
              "and": [
                {"trDateHeureEstimeeArrivee": {"gte": jourDebut}},
                {"trDateHeureEstimeeArrivee": {"lte": jourFin}}
              ]
            },
            {
              "and": [
                {"trDateHeureReelleArrivee": {"gte": jourDebut}},
                {"trDateHeureReelleArrivee": {"lte": jourFin}}
              ]
            }
          ]
        }
      }
      console.log(filter)
      var url = "/api/trTrains?filter=" + JSON.stringify(filter) + "&access_token=" + this.$user.id;
      axios.get(url)
        .then(function(response) {
          var trains = response.data;
          trains.forEach(function (train) {
            train.trajet = train.trLieuDepart + "-" + train.trLieuArrivee
            var lieu = this.lieus.find(l => l.liCode.toLowerCase() == train.trLieuDepart.toLowerCase())
            if (lieu != undefined) {
              train.trajetTooltip = lieu.liDesignation
            }
            else {
              train.trajetTooltip = train.trLieuDepart
            }
            lieu = this.lieus.find(l => l.liCode.toLowerCase() == train.trLieuArrivee.toLowerCase())
            if (lieu != undefined) {
              train.trajetTooltip += " -> " + lieu.liDesignation
            }
            else {
              train.trajetTooltip += " -> " + train.trLieuArrivee
            }
          }.bind(this));
          this.trains = response.data;
          this.trainsFiltres = this.trains;
          this.loading = false;
        }.bind(this))
        .catch(error => {
          this.snackMessage = error.message + " : " + error.response.statusText;
          this.snackErreur = true
          this.loading = false;
          this.trains = [];
          this.trainsFiltres = [];
        })
    },

    aujourdhui() {
      this.jourDebut = new Date();
      this.jourDebut.setHours(0,0,0,0)
      this.jourFin = new Date();
      this.jourFin.setTime(this.jourDebut.getTime() + 86400000 * 1);
      this.refresh();
    },

    jMoinsUn() {
      if (this.jourDebut == undefined) {
        this.aujourdhui();
        return;
      }
      this.jourDebut.setTime(this.jourDebut.getTime() - 86400000);
      this.jourFin.setTime(this.jourFin.getTime() - 86400000);
      this.refresh();
    },

    jPlusUn() {
      if (this.jourDebut == undefined) {
        this.aujourdhui();
        return;
      }
      this.jourDebut.setTime(this.jourDebut.getTime() + 86400000);
      this.jourFin.setTime(this.jourFin.getTime() + 86400000);
      this.refresh();
    },

    getColor (retard) {
      if (parseInt(retard) > 0) return 'red'
      else return 'green'
    },

    clickTableau(e) {
      console.log(e);
    },

    dateToYYYYMMDD(date) {
      return date.getFullYear() + "-" + ("00" + (date.getMonth() + 1)).slice(-2) + "-" + ("00" + (date.getDate())).slice(-2) 
    },

    dateToDDMMYYYY(date) {
      return ("00" + (date.getDate())).slice(-2) + '-' + ("00" + (date.getMonth() + 1)).slice(-2) + "-" + date.getFullYear()
    },


    computeDate(sd) {
      if (sd == null) {
        return undefined
      }
      var d = new Date(sd);
      if (d == undefined) {
        return undefined
      }
      if (sd.startsWith("19")) {
        return undefined
      }
      else {
        try
         {
           console.log(d, d.toISOString());
            return d.toISOString().replace("T", " ").substr(0,16)
         }
         catch(error) {
          return ""; 

         }
      }
    },

    choixJourDebut() {
      this.datepicker1 = false
      this.datepicker1sJour = this.parseDate(this.datepicker1Jour);
    },

    choixJourFin() {
      this.datepicker2 = false
      this.datepicker2sJour = this.parseDate(this.datepicker2Jour);
    },

    parseDate (date) {
      if (!date) return null

      const [day, month, year] = date.split('-')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },

    periode() {
      this.jourDebut = new Date(this.datepicker1Jour);
      this.jourFin = new Date(this.datepicker2Jour);
      this.refresh()
    }
    
  }
}
</script>

<style>

</style>